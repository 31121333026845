import React from 'react'
import { config, useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

const Description = styled(animated.div)`
  max-width: 960px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
  padding-top: 0.5em;
`

const Aftercare = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })

  return (
    <Layout>
      <SEO title="Aftercare | Kiki" desc="Kiki's Tattoo Service | @ink.by.kiki | Vancouver, BC" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 10]}>
        <animated.h1 style={titleAnimation}>Aftercare</animated.h1>
        <Description style={descAnimation}>
          <ul>
            <li>Remove bandage after <b>4-6</b> days (unless otherwise instructed). Wash your hands thoroughly and dry with <u>paper towel</u> before handling your tattoo. </li>
            <ul>
              <li> Avoid vigorous exercise while you have the bandage on. The wrap will trap sweat/moisture and you want to avoid soaking the tattoo.</li>
              <li>Tip: Remove the wrap at the end of a warm shower. This will loosen up the bandage and make your life a lot easier. Remove from TOP to BOTTOM, peeling the wrap over itself.</li>
              <li>Your tattoo may be “weeping” plasma, this is normal! Wash with <b>LUKEWARM</b> water and a mild soap (more details on soap below). Gently pat dry with <u>paper towel</u>.</li>
              <li><b>Do not</b> rebandage your tattoo, it needs to breathe.</li>    
              <ul>
                <li> Put on clothing that is clean, and preferably soft and breathable.</li> 
              </ul>       
            </ul> 
            <li>For the duration of the healing process, wash your tattoo <u>morning and night</u> with a mild, unscented soap (Dove, Neutrogena, & most other brands all have acceptable products)</li>
            <ul>
              <li>Best not to use a soap that lists alcohol as one of its first ingredients.</li>
              <li><b>Do NOT</b> use a washcloth or loofah to wash your tattoo. Use your clean hands!</li>
              <li>Gently pat tattoo dry with paper towel. Avoid cloth towels, they can harbour bacteria. </li>
              <li><b>Do NOT</b> soak/submerge your tattoo for the first 2 weeks (ie. No baths, hot tubs, swimming, etc.). </li>
              <li>Showers are fine! However, best practice would be to limit the amount of time you allow your new tattoo to be in the direct firing path of the shower head. </li>
              <li><b>Do NOT</b> over wash your tattoo! This can remove essential bacterial and oil that will help along your healing. </li>
            </ul>
            <li>Apply a <b>LIGHT</b> layer of ointment or unscented lotion to the tattoo after washing. Gently pat the area, rather than aggressively rubbing, to allow absorption</li>
            <ul>
              <li>Key word here is LIGHT! Suffocating your new tattoo with product can result in loss of ink, or worse, infection. If you're concerned you've applied too much, blot with paper towel. Your skin will have taken up what it needs. </li>
              <li><b>Suggested ointments (available on Amazon):</b> Hustle Butter Cream, Tattoo Goo</li>
              <li><b>Suggested lotions (available at most drug stores):</b> Aveno, Glaxal Base</li>
              <li><b>Other less common products:</b> EltaMD lotion, Kopari Coconut Melt, After Inked Tattoo Lotion</li>
              <li><u>Be sure to take a peek at the ingredients of the above products for things you’re sensitive to!</u></li>
            </ul>
            <li>Following wrap removal, your tattoo will soon feel dry and begin flaking like a sunburn. </li>
            <ul>
              <li><b>DO NOT</b> pick, or peel at the scab/hard layer forming over the tattoo, this can pull out ink and result in loss of fine detail!</li>
              <li> Anything you can do to minimize friction against the tattoo during the peeling phase will put you in the best position to preserve detail. Avoid abrasive clothing and activities that result in rubbing against the tattoo.</li>
              <li>An artist can tell when a tattoo has been picked at, so let it do its thing. </li>
            </ul>
            <li>Your tattoo may be itchy during the healing process (this is normal), <b>DO NOT</b> scratch it! If necessary, apply lotion/ointment to soothe itching</li>
            <li>PLEASE do the above for <b>~2</b> weeks for optimal healing results</li>
            <ul>
              <li>Avoid wearing dirty clothing (risk of infection) during this period </li>
              <li>Avoid wearing too tight clothing (risk of ink loss) during this period</li>
              <li>Avoid directly exposing your new tattoo to the sun during this period. UV rays can dramatically fade a healing tattoo.</li>
              <ul>
                <li>Note: A lot of sun exposure over the lifetime of the tattoo means more fading!</li>
              </ul>
              <li><b>Do NOT</b> submerge your tattoo during this period.</li>
            </ul>
          </ul>
        </Description>
        <h3>Extra Info</h3>
        <ul>
          <li>DO wear sunblock on your tattoo once it’s healed! </li>
          <li>Wear something old, but clean on the first night after you remove your bandage. Your tattoo may “weep” plasma that can get onto your clothing/bedding. </li>
          <li>Consider your tattoo an open wound. DO NOT touch with unclean hands! </li>
          <li>You may experience swelling/bruising around your tattoo. Some people bruise easily, others do not. </li>
        </ul>
      </AnimatedBox>
    </Layout>
  )
}

export default Aftercare
